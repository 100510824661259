import { getFunctions, httpsCallable } from "firebase/functions";
import config from "../../config.json";
import { log } from "../../components/utils/Utils";

export function registerBusinessCustomer(
  businessName,
  emailAddress,
  givenName,
  lastName,
  mobileNumber,
  birthDate,
  gender,
  abn,
  businessPostCode,
  inviteCode,
  avatarUrl,
  latestTcPpVersSigned,
  latestTcPpVersSignIp
) {
  const data = {
    business_name: businessName,
    email_address: emailAddress,
    given_name: givenName,
    last_name: lastName,
    mobile_number: mobileNumber,
    birth_date: birthDate,
    gender: gender,
    abn: abn,
    business_post_code: businessPostCode,
    referred_by: inviteCode,
    avatar_url: avatarUrl,
    latest_tc_pp_vers_signed: latestTcPpVersSigned,
    latest_tc_pp_vers_sign_ip: latestTcPpVersSignIp,
  };
  const functions = getFunctions();
  const findRider = httpsCallable(functions, config.registerBusinessCustomer);
  log(data);
  return findRider(data);
}
