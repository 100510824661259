import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import { initializeApp } from "firebase/app";
import firebaseConfig from "../src/firebase/firebaseConfig";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getStorage } from "firebase/storage";
import { getRemoteConfig } from "firebase/remote-config";

const root = ReactDOM.createRoot(document.getElementById("root"));

const app = initializeApp(firebaseConfig);
export const remoteConfig = getRemoteConfig(app);
getStorage(app);

initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(
    process.env.REACT_APP_FIREBASE_APPCHECK_KEY
  ),
  isTokenAutoRefreshEnabled: true,
});

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
