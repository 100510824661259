import React from "react";
import "./progress.scss";
function Progress({ second, third }) {
  return (
    <div className="progress-bar">
      <ul>
        <li>
          <div className="wrapper">
            <div className="inner-wrapper">
              <div className="number">1</div>
              <div className="name">Step</div>
            </div>
          </div>
        </li>
        <li className={second ? "fade" : null}>
          <div className="line"></div>
          <div className="wrapper">
            <div className="inner-wrapper">
              <div className="number">2</div>
              <div className="name">Step</div>
            </div>
          </div>
        </li>
        <li className={third ? "fade" : null}>
          <div className="line"></div>
          <div className="wrapper">
            <div className="inner-wrapper">
              <div className="number">3</div>
              <div className="name">Step</div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default Progress;
