import React from "react";

const Select = ({
  name,
  label,
  options,
  error,
  defaultValue = "Select your Option",
  disabledDefaultValue = true,
  disableSelect = true,
  ...rest
}) => {
  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <select
        name={name}
        id={name}
        {...rest}
        className="form-control form-select"
        disabled={disableSelect}
      >
        <option value="" disabled={disabledDefaultValue}>
          {defaultValue}
        </option>
        {options.map((option) => (
          <option key={option.id} value={option.name}>
            {option.name}
          </option>
        ))}
      </select>
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default Select;
