import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import logo from "../../assets/bearer-logo-footer.svg";
import emailLogo from "../../assets/email-newletter.svg";
import aus from "../../assets/bearer-logo-aust.svg";
import instagram from "../../assets/instagram.svg";
import facebook from "../../assets/facebook.svg";
import twitter from "../../assets/twitter.svg";
import linkedin from "../../assets/linkedin.svg";
import "./footer.scss";
import Input from "../input/Input";
import config from "../../config.json";
import * as footerServicve from "./footerServices";
import Spinner from "../spinner/Spinner";
import { ToastContainer, toast } from "react-toastify";

function Footer() {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isLoading, setLoading] = useState(false);

  const handleNewsFeed = async () => {
    if (emailError.length > 5) {
      return;
    }
    setLoading(true);
    try {
      const result = await footerServicve.websiteNewsletter(email);
      setLoading(false);
      if (result) {
        switch (result.data.code) {
          case 200:
            toast.success("Successfully subscribed!");
            setEmail("");
            return;
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  };

  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };

  let baseUrl = window.localStorage.getItem(config.bearerWebsiteBaseUrl);
  const handleEmail = (value) => {
    setEmail(value);
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (!regex.test(value)) {
      setEmailError("Email address is not valid!");
    } else {
      setEmailError("");
    }
  };
  return (
    <div className="footer">
      <ToastContainer />
      {renderSpinner()}
      <Container fluid>
        <div className="logo-wrapper">
          <Row>
            <Col xs={12} sm={12} md={7} lg={7}>
              <a href={baseUrl} target="_blank" rel="noreferrer">
                <img src={logo} className="logo" alt="logo" />
              </a>
            </Col>
            <Col xs={12} sm={12} md={5} lg={5}>
              <p>Parcels are closer to their destinations than ever!</p>
            </Col>
          </Row>
        </div>
        <div className="middle-footer-info">
          <Row>
            <Col xs={12} sm={12} md={7} lg={7}>
              <Row>
                <Col>
                  <ul>
                    <li>
                      <a href={baseUrl} target="_blank" rel="noreferrer">
                        Bearer for Everyone
                      </a>
                    </li>
                    <li>
                      <a
                        href={baseUrl + "/Business"}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Bearer for Business
                      </a>
                    </li>
                    <li>
                      <a
                        href={baseUrl + "/Bearers"}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Become a Bearer
                      </a>
                    </li>
                    <li>
                      <a
                        href={baseUrl + "/About"}
                        target="_blank"
                        rel="noreferrer"
                      >
                        About Bearer
                      </a>
                    </li>
                    <li>
                      <a
                        href={baseUrl + "/Blog"}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Bearer Blog
                      </a>
                    </li>
                  </ul>
                </Col>
                <Col>
                  <ul>
                    <li>
                      <a
                        href={baseUrl + "/FAQs"}
                        target="_blank"
                        rel="noreferrer"
                      >
                        FAQs
                      </a>
                    </li>
                    <li>
                      <a
                        href={baseUrl + "/Support"}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Support
                      </a>
                    </li>
                    <li>
                      <a
                        href={baseUrl + "/Packaging"}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Packaging
                      </a>
                    </li>
                    <li>
                      <a
                        href={baseUrl + "/Contact"}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Contact Us
                      </a>
                    </li>
                    {/* <li>
                    <a
                        href={baseUrl + "/Packaging"}
                        target="_blank"
                        rel="noreferrer"
                      >Downloads</a>
                    </li> */}
                  </ul>
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={12} md={5} lg={5}>
              <div className="newsletter">
                <p>Subscribe to our Newsletter to get the latest updates</p>
                <div className="newslette-input-wrapper">
                  <img src={emailLogo} alt="email-logo" />
                  <Input
                    type="String"
                    name="profileFullName"
                    value={email}
                    error={emailError}
                    onChange={(e) => handleEmail(e.target.value)}
                    placeHolder="Enter your email"
                  />
                  <button className="subscribe" onClick={handleNewsFeed}>
                    Subscribe
                  </button>
                </div>
                <div className="social-wrapper">
                  <div className="social-link">
                    <ul className="social">
                      <li>
                        <a
                          href="https://www.instagram.com/Bearer.AU/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={instagram} alt="" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.facebook.com/Bearer.AU"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={facebook} alt="" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://twitter.com/Bearer_AU"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={twitter} alt="" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.linkedin.com/company/bearer-au/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={linkedin} alt="" />
                        </a>
                      </li>
                    </ul>
                    <div className="privacy">
                      <ul>
                        <li>
                          <a
                            href={baseUrl + "/Legal"}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Legal
                          </a>
                        </li>
                        <li>
                          <a
                            href={baseUrl + "/Legal/PrivacyPolicy"}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Privacy Policy
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="aus-logo">
                    <img src={aus} className="aus" alt="logo" />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="cite">
          <p>
            © 2024 Bearer | SeyCo Pty Ltd. <span>ABN: 64 626 871 225</span>
            <span>All rights reserved</span>
          </p>
        </div>
      </Container>
    </div>
  );
}

export default Footer;
