import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Progress from "../../components/progress/Progress";
import "./privacy.scss";
import config from "../../config.json";
function PrivacyPolicy({ handlePage }) {
  const [isPrivacyPolicy, setPrivacyPolicy] = useState(false);
  const [
    isBearerBusinessCustomerAgreement,
    setBearerBusinessCustomerAgreement,
  ] = useState(false);

  let privacyPolicyUrl = window.localStorage.getItem(
    config.businessCustomerPpUrl
  );
  let bearerCustomerAgreement = window.localStorage.getItem(
    config.businessCustomerTcUrl
  );

  let businessCustomerLandingpageUrl = window.localStorage.getItem(
    config.businessCustomerLandingpageUrl
  );


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Progress second={true} third={true} />
      <div className="privacy-policy">
        <Container fluid>
          <Row>
            <Col>
              <h2>
                G’day and welcome to <span>Bearer</span>.
              </h2>
              <p>
                In order to complete your registration during this onboarding
                process, we would ask you to provide some of your “Business and
                Personal Information” that could be stored, used or shared for
                legal purposes including but not limited to: ID Verification,
                AML checks in Australia by our third party partners.
                <br />
                <br />
                For all approved users, some of the provided details (deemed to
                be necessary for the platform to operate normally) would be kept
                on our servers (i.e: for keeping track of orders and financial
                transactions) and others would be only used by our Web / Mobile
                / Desktop applications for regular user interactions.
                <br />
                <br />
                For more information about our Terms of Service and your
                Obligations and the consents, please read all bellow documents
                carefully, tick the check boxes next to them and press AGREE to
                continue.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="checkbox-wrapper">
                <label>
                  <input
                    type="checkbox"
                    checked={isPrivacyPolicy}
                    onChange={() => setPrivacyPolicy((prev) => !prev)}
                  />
                  <span>
                    <a href={privacyPolicyUrl} target="_blank" rel="noreferrer">
                      Bearer Privacy Policy
                    </a>
                  </span>
                </label>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="checkbox-wrapper last">
                <label>
                  <input
                    type="checkbox"
                    checked={isBearerBusinessCustomerAgreement}
                    onChange={() =>
                      setBearerBusinessCustomerAgreement((prev) => !prev)
                    }
                  />
                  <span>
                    <a
                      href={bearerCustomerAgreement}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Bearer Business Customer Agreement
                    </a>
                  </span>
                </label>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                Please confirm that you have reviewed, and agree to each of the
                provided documents above and you acknowledge that you must
                accept above Consents in order to start your application as a
                Business Cuatomer with Bearer, by clicking AGREE bellow.
              </p>
            </Col>
          </Row>
          <Row>
            <Col></Col>
            <Col>
              <button
                className="btn disabled-btn"
                onClick={() => {
                  window.location.replace(businessCustomerLandingpageUrl);
                }}
              >
                DISAGREE
              </button>
            </Col>
            <Col>
              <button
                className="btn submit-btn"
                onClick={handlePage}
                disabled={
                  isBearerBusinessCustomerAgreement && isPrivacyPolicy
                    ? false
                    : true
                }
              >
                AGREE
              </button>
            </Col>
            <Col></Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default PrivacyPolicy;
