import { getFunctions, httpsCallable } from "firebase/functions";
import config from "../../config.json";
import { log } from "../utils/Utils";

export function websiteNewsletter(email) {
  const data = { email_address: email, webpage_id: "BusinessOnboarding" };
  const functions = getFunctions();
  const findRider = httpsCallable(functions, config.websiteNewsletter);
  log(data);
  return findRider(data);
}
