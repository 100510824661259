import React, { useState, useEffect } from "react";
import PrivacyPolicy from "../privacyPolicy/PrivacyPolicy";
import Verification from "../verification/Verification";
import Details from "../details/Details";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import * as remote from "./firebaseRemoteConfig";
import "react-toastify/dist/ReactToastify.css";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import config from "../../config.json";
import Input from "../../components/input/Input";
import {
  signOut,
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth";
import { log } from "../../components/utils/Utils";

import setHotjar from "../../components/common/hotjar";
import GoogleTagManager from "../../components/common/googleTagManager";
import FacebookPixel from "../../components/common/FacebookPixel";
function Home() {
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [modalBtnTitle, setModalBtnTitle] = useState("");

  const [condition, setCondition] = useState(4);
  const [showModal, setShowModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [emailAddressError, setEmailAddressError] = useState("");
  const [environment, setEnvironment] = useState("");
  const [showModalRemoteConfig, setShowModalRemoteConfig] = useState(false);
  const [
    showModalRemoteConfigOnboardingIsRunning,
    setShowModalRemoteConfigOnboardingIsRunning,
  ] = useState(false);

  const auth = getAuth();
  const getRemoteConfig = async () => {
    try {
      await remote.getRemoteConfigData();
      setEnvironment(localStorage.getItem(config.environment));
      if (localStorage.getItem(config.environment) === "PRODUCTION") {
        setHotjar();
      }
      if (
        localStorage.getItem(config.bearerCloudfunctionsIsRunning) &&
        localStorage
          .getItem(config.bearerCloudfunctionsIsRunning)
          .toLowerCase()
          .includes("true")
      ) {
        if (
          localStorage.getItem(
            config.bearerBusinessCustomerOnboardingIsRunning
          ) &&
          localStorage
            .getItem(config.bearerBusinessCustomerOnboardingIsRunning)
            .toLowerCase()
            .includes("true")
        ) {
          getIp();
        } else {
          setShowModalRemoteConfigOnboardingIsRunning(true);
        }
      } else {
        setShowModalRemoteConfig(true);
      }
    } catch (err) {
      log(err);
    }
  };
  const getIp = async () => {
    try {
      const response = await fetch("https://ipapi.co/json/");
      if (!response.ok) {
        signOut(auth).then(() => {});
        setModalTitle("IP Access");
        setModalMessage(
          "For legal purposes, during the registration, we need to have access to your IP address. Please turn off anti-virus software and try again."
        );
        setModalBtnTitle("OK");
        setShowModal(true);
        localStorage.removeItem("ip");
      }
      const data = await response.json();
      localStorage.setItem("ip", data.ip);
      checkIfSignedIn();
    } catch (err) {
      signOut(auth).then(() => {});
      setModalTitle("IP Access");
      setModalMessage(
        "For legal purposes, during the registration, we need to have access to your IP address. Please turn off anti-virus software and try again."
      );
      setModalBtnTitle("OK");
      setShowModal(true);
      localStorage.removeItem("ip");
    }
  };

  useEffect(() => {
    getRemoteConfig();
  }, []);

  const checkIfSignedIn = () => {
    const auth = getAuth();
    if (isSignInWithEmailLink(auth, window.location.href)) {
      let email = window.localStorage.getItem(config.emailForSignIn);
      if (!email) {
        setShowEmailModal(true);
      } else {
        signInWithEmail(email);
      }
    } else {
      setCondition(1);
    }
  };

  const signInWithEmail = (email) => {
    window.localStorage.setItem(config.emailForSignIn, email);
    signInWithEmailLink(auth, email, window.location.href)
      .then((result) => {
        if (result.user.uid !== null) {
          window.localStorage.setItem("uid", result.user.uid);
          setCondition(3);
        }
      })
      .catch(() => {
        setModalTitle("ᵔᴥᵔ");
        setModalMessage("Email verification link expired! ");
        setModalBtnTitle("Start again");
        setShowModal(true);
      });
  };

  const handlePageView = () => {
    switch (condition) {
      case 1:
        return (
          <>
            <Header />
            <PrivacyPolicy handlePage={() => setCondition(2)} />
            <Footer />
          </>
        );
      case 2:
        return (
          <>
            <Header />
            <Verification />
            <Footer />
          </>
        );
      case 3:
        return (
          <>
            <Header />
            <Details />
            <Footer />
          </>
        );
      default:
        return null;
    }
  };

  const handleModalBtn = () => {
    if (modalTitle !== "IP Access") {
      let businessCustomerOnboardingUrl = window.localStorage.getItem(
        config.businessCustomerOnboardingUrl
      );
      window.localStorage.removeItem(config.userEmailAddress);
      window.location.replace(businessCustomerOnboardingUrl);
    } else {
      checkIfSignedIn();
      setShowModal(false);
    }
  };

  const handleEmail = (value) => {
    setEmailAddress(value);
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (!regex.test(value)) {
      return setEmailAddressError("Email address is not valid!");
    } else {
      setEmailAddressError("");
    }
  };

  return (
    <div>
      {environment === "PRODUCTION" && (
        <div>
          <GoogleTagManager />
          <FacebookPixel />
        </div>
      )}
      {condition !== 4 ? handlePageView() : null}
      <Modal show={showModal} className="modal-popup-custom">
        <Modal.Header>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{modalMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleModalBtn}>
            {modalBtnTitle}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showEmailModal} className="modal-popup-custom">
        <Modal.Header>
          <Modal.Title>Email verification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            For your account security, please enter the Email Address you
            provided earlier for your new account.
          </p>
          <div className="input-wrapper">
            <Input
              label="Email:"
              type="String"
              name="email address"
              value={emailAddress}
              onChange={(e) => handleEmail(e.target.value)}
              error={emailAddressError}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              if (emailAddressError === "") {
                setShowEmailModal(false);
                signInWithEmail(emailAddress);
              }
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModalRemoteConfig} className="modal-popup-custom">
        <Modal.Body>
          <p>
            {localStorage.getItem(config.bearerCloudfunctionsStatusMessageText)}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              if (
                localStorage
                  .getItem(config.bearerCloudfunctionsStatusMessageButtonAction)
                  .toLowerCase()
                  .includes("support")
              ) {
                window.open(
                  localStorage.getItem(config.riderSupportWebpageUrl)
                );
              } else {
                window.open(
                  localStorage.getItem(config.bearerCloudfunctionsActionUrl)
                );
              }
            }}
          >
            {localStorage.getItem(
              config.bearerCloudfunctionsStatusMessageButtonAction
            ) &&
            localStorage
              .getItem(config.bearerCloudfunctionsStatusMessageButtonAction)
              .toLowerCase()
              .includes("support")
              ? "Support"
              : "Learn More"}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showModalRemoteConfigOnboardingIsRunning}
        className="modal-popup-custom"
      >
        <Modal.Body>
          <p>
            {localStorage.getItem(
              config.bearerBusinessCustomerOnboardingStatusMessageText
            )}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              if (
                localStorage.getItem(
                  config.bearerBusinessCustomerOnboardingStatusMessageButtonAction
                ) &&
                localStorage
                  .getItem(
                    config.bearerBusinessCustomerOnboardingStatusMessageButtonAction
                  )
                  .toLowerCase()
                  .includes("support")
              ) {
                window.open(
                  localStorage.getItem(config.businessCustomerSupportWebpageUrl)
                );
              } else {
                window.open(
                  localStorage.getItem(
                    config.bearerBusinessCustomerOnboardingActionUrl
                  )
                );
              }
            }}
          >
            {localStorage.getItem(
              config.bearerBusinessCustomerOnboardingStatusMessageButtonAction
            ) &&
            localStorage
              .getItem(
                config.bearerBusinessCustomerOnboardingStatusMessageButtonAction
              )
              .toLowerCase()
              .includes("support")
              ? "Support"
              : "Learn More"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Home;
