import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./header.scss";
import logo from "../../assets/bearer-logo.svg";
import config from "../../config.json";
function Header() {
  let baseUrl = window.localStorage.getItem(config.bearerWebsiteBaseUrl);
  return (
    <div className="header">
      <Container fluid>
        <Row>
          <Col sm={12} xs={12} ms={4} lg={4}>
            <h1>
              <a href={baseUrl} target="_blank" rel="noreferrer">
                <img src={logo} alt="logo" />
              </a>
            </h1>
          </Col>
          <Col sm={12} xs={12} ms={8} lg={8}>
            <ul>
              <li>
                <a href={baseUrl} target="_blank" rel="noreferrer">
                  Bearer Home
                </a>
              </li>
              <li>.</li>
              <li>
                <a href={baseUrl + "/About"} target="_blank" rel="noreferrer">
                  About us
                </a>
              </li>
              <li>.</li>
              <li>
                <a href={baseUrl + "/FAQs"} target="_blank" rel="noreferrer">
                  FAQs
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Header;
