import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../common/Common.scss";

const Spinner = () => {
  return (
    <div className="spinner-outer-block">
      <div className="spinner-block">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  );
};

export default Spinner;
